import React, { useEffect } from 'react';
import { isLoggedIn } from '../services/authService';
import { navigate } from 'gatsby';

// plugins styles from node_modules
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
// plugins styles downloaded
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0';
import 'assets/css/custom.css';

//MAN 24/4/24 My Custom CSS Stylesheets
import 'assets/noels_customCSS/quickbooksconnect.css';

//import Index from "views/Index.js";
import Loading from 'components/loading.js';

const IndexPage = () => {
  useEffect(() => {
    if(!isLoggedIn()) {
      navigate('/auth/login');
    } else {
      navigate('/dashboard/home');
    }
  });

  return(
    <Loading />
  );
};

export default IndexPage;
